import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import auth from './auth';
import users from './users';
import projects from './project';
import videos from './video';

/* Converting an object whose values are different reducing functions into a single reducing function using combineReducers helper function. */
const reducer = combineReducers({
    // here we will be adding reducers
    auth,
    users,
    projects,
    videos
});
// Passing combine reducers to configureStore
const store = configureStore({
    reducer,
})
export default store;