import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchVideos } from "store/video";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from "components/Common/Constants";
import { useEffect } from "react";
import { setSearchObjOfVideo } from "store/video";
import { selectProject } from "store/project";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Helpers from "Helpers";
import DateRangeModal from "./DateRangeModal";
import { setGlobleVideofilters } from "store/video";

function Filter(props) {
  const { projectFilter } = useSelector((state) => state?.projects);
  const { selectedProject } = useSelector((state) => state?.projects);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  var nav = useNavigate();
  useEffect(() => {
    if (searchParams.get("filter_project_id")) {
      dispatch(setGlobleVideofilters({ filter_project_id: searchParams.get("filter_project_id") }))
    }
  }, [])
  const formik = useFormik({
    initialValues: {
      filter_title: "",
      filter_project_id: searchParams.get("filter_project_id") ? searchParams.get("filter_project_id") : "",
      start_date: null,
      end_date: null,
    },
    onSubmit: async (values) => {
      let value = {
        filter_title: values?.filter_title || undefined,
        filter_project_id: values?.filter_project_id || undefined,
        date_range:
          (Helpers?.formatDateFilter(values?.start_date) &&
            Helpers?.formatDateFilter(values?.end_date))
            ? `${Helpers?.formatDateFilter(
              values?.start_date
            )} 00:00:00_TO_${Helpers?.formatDateFilter(
              values?.end_date
            )} 23:59:59`
            : undefined,
      };
      await dispatch(fetchVideos(value));
      dispatch(setGlobleVideofilters(value))
      nav(`/media`);
    },
  });

  const resetData = async () => {
    dispatch(selectProject(null));
    dispatch(setSearchObjOfVideo({}));
    // formik.resetForm();
    formik.setValues({
      filter_title: "",
      filter_project_id: "",
      start_date: null,
      end_date: null,
    })
    dispatch(setGlobleVideofilters({}))
    await dispatch(fetchVideos());
    nav(`/media`);
  };
  // Add min height for select dropdown menu along with scrolling
  const MenuProps = {
    classes: { paper: "select-filter" },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  return (
    <>
      <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
        <MDBox mt={4} mb={1}>
          <MDInput
            style={{ minWidth: 220 }}
            type="text"
            label={props.label}
            name={props.name}
            value={formik.values.filter_title}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          &nbsp;
          <FormControl>
            <Stack direction="row">
              <InputLabel id="demo-multiple-name-label">Project</InputLabel>
              <Select
                sx={{ minWidth: 185, minHeight: 44 }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                label="Project"
                name="filter_project_id"
                value={formik.values?.filter_project_id}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <MenuItem value="">Select Project</MenuItem>
                {projectFilter?.map((project) => (
                  <MenuItem value={project.id} key={project.id}>
                    {project.title}
                  </MenuItem>
                ))}
              </Select>
              &nbsp;
              <DateRangeModal
                date={new Date()}
                start_date={formik?.values?.start_date}
                end_date={formik?.values?.end_date}
                onChange={(selection) => {
                  formik.setValues({
                    ...formik.values,
                    start_date: selection?.startDate || null,
                    end_date: selection?.endDate || null,
                  });
                }}
              />
              &nbsp;
            </Stack>
          </FormControl>
          &nbsp;
          <MDButton variant="gradient" color="info" type="submit">
            Search
          </MDButton>
          &nbsp;
          <MDButton
            variant="gradient"
            type="reset"
            color="primary"
            onClick={resetData}
          >
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}

export default Filter;
