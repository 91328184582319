import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Service from "Service";

const initialVideo = [];

// Slice
const slice = createSlice({
  name: "videos",
  initialState: {
    videos: initialVideo,
    project: [],
    searchObj: {},
    videoFilters: {}
  },
  reducers: {
    // Add videos data to videos state
    fetch: (state, action) => {
      state.videos = action.payload.videos;
    },
    // Add videos data to videosData state for update
    edit: (state, action) => {
      state.videosData = action.payload;
    },
    // Add project title to project state for project search dropdown
    videoFilter: (state, action) => {
      state.project = action.payload;
    },
    searchObj: (state, action) => {
      state.searchObj = action.payload;
    },
    videoFilters: (state, action) => {
      state.videoFilters = action.payload;
    },
  },
});
export default slice.reducer;
// Actions
const { fetch, edit, videoFilter, searchObj, videoFilters } = slice.actions;
export const setGlobleVideofilters = (filter) => async (dispatch) => {
  dispatch(videoFilters(filter));
}
export const fetchVideos = (filter, url) => async (dispatch) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    if (filter) {
      config["params"] = filter;
    }
    if (!url) {
      url = `${process.env.REACT_APP_BaseUrl}/videos`;
    }
    // API to Fetch Media Data [images/videos]
    const res = await axios.get(url, config);
    dispatch(fetch(res.data));
    return res;
  } catch (e) {
    console.error(e.message);
    return e.message;
  }
};
export const uploadVideo = async (fileData) => {
  try {
    // API to Upload File to Media
    const res = await axios.post(
      `${process.env.REACT_APP_BaseUrl}/upload-file`,
      fileData,
      Service.config()
    );
    return res;
    // dispatch(edit(res.data.video));
  } catch (e) {
    console.error(e.message);
    return e;
  }
};
export const addVideo = (videoData) => async (dispatch) => {
  try {
    // API to Add images/videos Data to Media
    const res = await axios.post(
      `${process.env.REACT_APP_BaseUrl}/videos`,
      videoData,
      Service.config()
    );
    return res;
  } catch (e) {
    console.error(e.message);
    return e.message;
  }
};
export const getVideo = (videoId) => async (dispatch) => {
  try {
    // API to Get Media Data
    const res = await axios.get(
      `${process.env.REACT_APP_BaseUrl}/video/${videoId}`,
      Service.config()
    );
    dispatch(edit(res.data.video));
    return res.data.video;
  } catch (e) {
    console.error(e.message);
    return e.message;
  }
};

export const editVideo = (videoId, videoData) => async (dispatch) => {
  try {
    // API to Update Media Data
    const res = await axios.post(
      `${process.env.REACT_APP_BaseUrl}/video-update/${videoId}`,
      videoData,
      Service.config()
    );
    return res;
  } catch (e) {
    console.error(e.message);
    return e;
  }
};
export const delVideo = (videoId) => async (dispatch) => {
  try {
    // API to Delete Media Data
    const res = await axios.post(
      `${process.env.REACT_APP_BaseUrl}/video-remove/${videoId}`,
      {},
      Service.config()
    );
    return res;
  } catch (e) {
    console.error(e.message);
    return e;
  }
};
// get all project list [lead access]
export const getProjects = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BaseUrl}/videos-filters`,
      Service.config()
    );
    dispatch(videoFilter(res.data.assignee_list));
  } catch (e) {
    console.error(e.message);
    return e;
  }
};

export const setSearchObjOfVideo = (search) => async (dispatch) => {
  try {
    dispatch(searchObj(search));
  } catch (e) {
    console.error(e.message);
    return e;
  }
};

// Function to create URL based on provided filters
function createUrl(filter) {
  let url = `${process.env.REACT_APP_BaseUrl}/video-bulk-remove`;

  // Check if filter?.date_range is present and append it to the URL
  if (filter?.date_range) {
    url += `?date_range=${filter?.date_range}`;
    // If other filters are also present, append '&' before adding the date_range filter
    if (filter?.filter_title || filter?.filter_project_id) {
      url += `&`;
    }
  } else {
    url += "?";
  }

  // Check if both filter_title and filter_project_id are present
  if (filter?.filter_title && filter?.filter_project_id) {
    url += `filter_title=${filter?.filter_title}&filter_project_id=${filter?.filter_project_id}`;
    return url;
  }
  // Check if only filter_title is present
  if (filter?.filter_title) {
    url += `filter_title=${filter?.filter_title}`;
    return url;
  }
  // Check if only filter_project_id is present
  if (filter?.filter_project_id) {
    url += `filter_project_id=${filter?.filter_project_id}`;
    return url;
  }
  // Return the URL as is if no filters are provided
  return url;
}

// Action to delete all videos
export const deleteAllVideos =
  (selectAllPage, selectRows, filter) => async (dispatch) => {
    // Convert selectRows array to comma-separated string
    const commaSeparatedString = JSON.stringify(selectRows)?.replace(
      /[\[\]']/g,
      ""
    );
    try {
      const res = await axios.post(
        createUrl(filter), // Call createUrl function to get the URL with applied filters
        {
          selected: selectAllPage ? "ALL" : commaSeparatedString,
        },
        Service.config()
      );
      return res;
    } catch (e) {
      console.error(e.message);
      return e;
    }
  };
