import Dashboard from "layouts/dashboard";
import ProjectList from "layouts/Projects/List";
import VideoList from "layouts/Videos/List";
// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "User",
    key: "dashboard",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    icon: <Icon fontSize="small">apps</Icon>,
    route: "/projects",
    component: <ProjectList />,
  },
  {
    type: "collapse",
    name: "Media",
    key: "media",
    icon: <Icon fontSize="small">image</Icon>,
    route: "/media",
    component: <VideoList />,
  }
];

export default routes;
