// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import DataTable from "commonComponents/Tables/DataTable";

// Data
import projectsTableData from "layouts/tables/data/projectsTableData";
import SignIn from "layouts/authentication/sign-in";
import MDButton from "components/MDButton";
import ProjectAdd from "./Add";
import { useEffect, useMemo, useState } from "react";
import ProjectEdit from "./Edit";
import VideoAdd from "layouts/Videos/Add";
import Filter from "./Filter";
import Pagination from "components/Custom/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { projectAssignee } from "store/project";
import SelectBtn from "./SelectBtn";

function ProjectList() {
  const {
    columns: pColumns,
    rows: pRows,
    projectId,
    pId,
    setProjectId,
    setPId,
    loader,
    TotalRecords,
    selectRows,
    selectAllPage,
  } = projectsTableData();
  const { auth } = useSelector((state) => state);
  const modifiedColumns = useMemo(() => {
    if (auth?.user?.is_lead === "yes") {
      return pColumns;
    } else {
      return pColumns.filter(column => column.accessor !== 'checkbox');
    }
  }, [auth, pColumns]);
  const [showProjects, setShowProjects] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showVideoModal, setUploadVideo] = useState(false);
  const { projects } = useSelector((state) => state.projects);
  const [filterData, setFilterData] = useState({});
  const dispatch = useDispatch();
  const handleShow = async () => {
    setShowProjects(true);
  };
  useMemo(() => {
    const fetchAssignee = async () => {
      await dispatch(projectAssignee());
    };
    fetchAssignee();
  }, []);
  useEffect(() => {
    if (projectId) {
      setShowEditModal(true);
    }
  }, [projectId]);
  useEffect(() => {
    if (pId) {
      setUploadVideo(true);
    }
  }, [pId]);

  return (
    <>
      {auth?.user ? (
        <DashboardLayout>
          <DashboardNavbar />
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Filter
                label="Search By Project Name"
                name="filter_title"
                setFilterData={setFilterData}
              />
            </Grid>
            <Grid item xs={4} mt={4}>
              {auth?.user.is_lead === "yes" && (
                <MDButton
                  style={{ float: "right" }}
                  variant="outlined"
                  color="info"
                  onClick={handleShow}
                >
                  Add Project
                </MDButton>
              )}
            </Grid>
          </Grid>
          {auth?.user?.is_lead === "yes" && (
            <SelectBtn
              selectRows={selectRows}
              selectAllPage={selectAllPage}
              TotalRecords={TotalRecords}
              filterData={filterData}
            />
          )}
          <MDBox pt={3} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  {/* <MDBox
                                            mx={2}
                                            mt={-3}
                                            py={3}
                                            px={2}
                                            variant="gradient"
                                            bgColor="info"
                                            borderRadius="lg"
                                            coloredShadow="info"
                                        >
                                            <MDTypography variant="h6" color="white">
                                                Manage Projects
                                            </MDTypography>
                                        </MDBox> */}

                  <MDBox>
                    <DataTable
                      table={{ columns: modifiedColumns, rows: pRows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={true}
                      noEndBorder
                      loader={loader}
                      TotalRecords={TotalRecords}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Pagination
            key="fetchProjects"
            type="fetchProjects"
            object={projects}
          />
          {showProjects && <ProjectAdd onShow={() => setShowProjects(false)} />}
          {showEditModal && (
            <ProjectEdit
              onShow={() => {
                setShowEditModal(false);
                setProjectId("");
              }}
              projectId={projectId}
            />
          )}
          {showVideoModal && (
            <VideoAdd
              pId={pId}
              onShow={() => {
                setUploadVideo(false);
                setPId("");
              }}
            />
          )}
        </DashboardLayout>
      ) : (
        <SignIn />
      )}
    </>
  );
}

export default ProjectList;
