// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import MDBadge from "components/MDBadge";
import Helpers from "Helpers";


function ViewMedia({ onShow }) {
    const { videosData } = useSelector(state => state.videos);
    let mediaType = Helpers.fileType(videosData?.filename);
    return (
        <>
            <Modal size='lg' show={onShow} onHide={() => onShow()} centered>
                <Modal.Header closeButton>
                    <Modal.Title><span style={ {fontSize:"large"} }>{videosData?.project.title}</span><span style={ {fontSize:"medium"} }> - {videosData?.title}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {mediaType === 'video' &&
                        <video width="750" height="500" controls >
                            <source src={videosData.link} type="video/mp4" />
                            <source src={videosData.link} type="video/quicktime" />
                        </video>
                    }

                    {mediaType === 'audio' &&
                        <audio controls>
                            <source src={videosData.link}/>
                        </audio>
                    }

                    {mediaType === 'image' && <a href={videosData.link} target="_blank">
                        <MDBox
                            component="img"
                            src={videosData.link}
                            alt={videosData.filename}
                            borderRadius="md"
                            shadow="md"
                            width="100%"
                            height="100%"
                            position="relative"
                            zIndex={1}
                            title="Click To Open"
                        />
                    </a>
                    }
                    {mediaType === '' && <MDBadge badgeContent='Failed to load! Try Again' color='warning' size="md" variant="contained" container />}
                    {/* <iframe src="https://www.youtube.com/embed/cWDJoK8zw58"></iframe> */}
                    {/* show img or video */}
                    {/* <img
                        src={videosData.link}
                        alt={videosData.filename}
                    /> */}
                </Modal.Body>

                <Modal.Footer>
                    <small style={ {fontSize:"small"} }>Uploaded By : {videosData?.uploaded_by?.name}</small>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ViewMedia;
