// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Data
import MDButton from "components/MDButton";

import { Modal } from "react-bootstrap";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { logout } from "store/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getToast } from "toaster";
import { changeUserPassword } from "store/users";

function ChangeUserPasswordModal({ onShow, title, userId }) {
  const Navigate = useNavigate();
  const dispatchFn = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { userData } = useSelector((state) => state.users);
  let initialValues = {
    new_password: "",
    retype_password: "",
  };

  // validation
  const formik = useFormik({
    initialValues: {
      change_password: initialValues,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      change_password: Yup.object({
        new_password: Yup.string()
          .required("Password is required")
          .min(8, "Your password is too short."),
          retype_password: Yup.string()
          .required("Confirm Password is required")
          .min(5, "Your password is too short.")
          .oneOf([Yup.ref("new_password")], "Passwords doesn't must match"),
      }),
    }),
    onSubmit: async (values) => {
      let res = await dispatchFn(changeUserPassword(userId, values?.change_password));
      if (res.data.message) {
        toast.success(res.data.message, getToast(3000));
        onShow();
        setTimeout(() => {
        //   autoLogout();
        }, 2000);
      } else {
        toast.error(res.data.error, getToast(3000));
        onShow();
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // handleClickShowNewPassword
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <>
      <Modal
        show={onShow}
        onHide={() => onShow()}
        centered={!title && true}
        backdrop="static"
      >
        <Modal.Header closeButton={!title && true}>
          <Modal.Title>{title ? title : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="filled-adornment-password">
                    New Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    name="change_password.new_password"
                    fullWidth
                    type={showNewPassword ? "text" : "password"}
                    value={formik.values.change_password.new_password}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {formik.errors.change_password?.new_password &&
                  formik.touched.change_password?.new_password && (
                    <Alert
                      severity="error"
                      sx={{
                        height: 50,
                      }}
                    >
                      <small>
                        {formik.errors.change_password?.new_password}
                      </small>
                    </Alert>
                  )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Confirm Password"
                  variant="standard"
                  name="change_password.retype_password"
                  value={formik.values.change_password.retype_password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                />
                {formik.errors.change_password?.retype_password &&
                  formik.touched.change_password?.retype_password && (
                    <Alert
                      severity="error"
                      sx={{
                        height: 50,
                      }}
                    >
                      <small>
                        {formik.errors.change_password?.retype_password}
                      </small>
                    </Alert>
                  )}
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" type="submit">
                  Update
                </MDButton>
                &nbsp;
                <MDButton
                  variant="gradient"
                  color="primary"
                  onClick={() => onShow()}
                >
                  Cancel
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChangeUserPasswordModal;
