// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Data
import MDButton from "components/MDButton";

import { Modal } from "react-bootstrap";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ChangePassword } from "../../store/users";
import { Alert, FormControl, Icon, IconButton, Input, InputAdornment, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { logout } from "store/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getToast } from "toaster";

function ChangePasswordModal({ onShow, title }) {
    const Navigate = useNavigate();
    const dispatchFn = useDispatch();
    const [showPassword, setShowPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false);

    let initialValues = {
        old_password: "",
        password: "",
        confirmation_password: ""
    };

    const autoLogout = () => {
        Navigate('/authentication/sign-in');
        return dispatchFn(logout());
    }

    // validation
    const formik = useFormik({
        initialValues: {
            change_password: initialValues
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            change_password: Yup.object({
                old_password: Yup.string()
                    .required("Please enter a old password"),
                password: Yup.string()
                    .required("Please enter a new password")
                    .min(8, 'Your password is too short.')
                    .test('passwords-match', 'Password should not match with old password', function (value) {
                        return this.parent.old_password !== value
                    }),
                confirmation_password: Yup.string()
                    .required("Please enter a confirm password")
                    .test('passwords-match', 'Passwords must match', function (value) {
                        return this.parent.password === value
                    }),
            })
        }),
        onSubmit: async (values) => {
            let res = await ChangePassword(values?.change_password);
            if (res.data.message) {
                toast.success(res.data.message, getToast(3000));
                onShow();
                setTimeout(() => {
                    autoLogout();
                }, 2000);
            } else {
                toast.error(res.data.error, getToast(3000));
                onShow();
            }
        }
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    // handleClickShowNewPassword
    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }

    return (
        <>
            <Modal show={onShow} onHide={() => onShow()} centered={!title && true} backdrop="static">
                <Modal.Header closeButton={!title && true}>
                    <Modal.Title>{title ? title : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MDBox pt={4} pb={3} px={3}>
                        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                            <MDBox mb={2}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel htmlFor="filled-adornment-password">Current Password</InputLabel>
                                    <Input
                                        name="change_password.old_password"
                                        fullWidth
                                        type={showPassword ? 'text' : 'password'}
                                        value={formik.values.change_password.old_password}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                {formik.errors.change_password?.old_password && formik.touched.change_password?.old_password && (
                                    <Alert severity="error" sx={{
                                        height: 50
                                    }}><small>{formik.errors.change_password?.old_password}</small></Alert>
                                )}
                            </MDBox>
                            <MDBox mb={2}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel htmlFor="filled-adornment-password">New Password</InputLabel>
                                    <Input
                                        id="standard-adornment-password"
                                        name="change_password.password"
                                        fullWidth
                                        type={showNewPassword ? 'text' : 'password'}
                                        value={formik.values.change_password.password}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                >
                                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                {formik.errors.change_password?.password && formik.touched.change_password?.password && (
                                    <Alert severity="error" sx={{
                                        height: 50
                                    }}><small>{formik.errors.change_password?.password}</small></Alert>
                                )}
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput type="password" label="Confirm Password" variant="standard"
                                    name="change_password.confirmation_password"
                                    value={formik.values.change_password.confirmation_password}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    fullWidth
                                />
                                {formik.errors.change_password?.confirmation_password && formik.touched.change_password?.confirmation_password && (
                                    <Alert severity="error" sx={{
                                        height: 50
                                    }}><small>{formik.errors.change_password?.confirmation_password}</small></Alert>
                                )}
                            </MDBox>
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" color="info" type="submit">
                                    Update
                                </MDButton>&nbsp;
                                <MDButton variant="gradient" color="primary" onClick={() => onShow()}>
                                    Cancel
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ChangePasswordModal;
