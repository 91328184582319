// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import DataTable from "commonComponents/Tables/DataTable";

// Data
import Pagination from "components/Custom/Pagination";
import MDButton from "components/MDButton";
import SignIn from "layouts/authentication/sign-in";
import videosTableData from "layouts/tables/data/videosTableData";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectFilters } from "store/project";
import VideoAdd from "./Add";
import VideoEdit from "./Edit";
import Filter from "./Filter";
import ViewMedia from "./View";
import SelectBtn from "./SelectBtn";

function VideoList() {
  const {
    columns: pColumns,
    rows: pRows,
    videoId,
    pId,
    setVideoId,
    viewMediaId,
    setViewMedia,
    loader,
    TotalRecords,
    selectRows,
    selectAllPage,
  } = videosTableData();
  const { auth } = useSelector((state) => state);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showVideoModal, setUploadVideo] = useState(false);
  const { videos } = useSelector((state) => state.videos);
  const dispatch = useDispatch();
  useEffect(() => {
    if (videoId) {
      setShowEditModal(true);
    }
    if (viewMediaId) {
      setShowMediaModal(true);
    }
  }, [videoId, viewMediaId]);

  // For get fetch project filter
  useMemo(() => {
    const fetch = async () => {
      await dispatch(fetchProjectFilters());
    };
    fetch();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [videos]);

  const handleShowMediaModal = () => {
    setUploadVideo(true);
  };
  return (
    <>
      {auth?.user ? (
        <DashboardLayout>
          <DashboardNavbar />
          {/* <MDButton variant="outlined" color="info" onClick={handleShow}>Add Video</MDButton> */}
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Filter label="Search By File Name" name="filter_title" />
            </Grid>
            <Grid item xs={4} mt={5}>
              <MDButton
                style={{ float: "right" }}
                onClick={handleShowMediaModal}
                variant="outlined"
                color="info"
              >
                Add Media
              </MDButton>
            </Grid>
          </Grid>
          {/* {auth?.user?.is_lead === "yes" && ( */}
            <SelectBtn
              selectRows={selectRows}
              selectAllPage={selectAllPage}
              TotalRecords={TotalRecords}
            />
          {/* )} */}
          <MDBox pt={3} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox>
                    <DataTable
                      table={{ columns: pColumns, rows: pRows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={true}
                      noEndBorder
                      loader={loader}
                      TotalRecords={TotalRecords}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Pagination key="fetchVideos" type="fetchVideos" object={videos} />
          {showVideoModal && (
            <VideoAdd
              onShow={() => {
                setUploadVideo(false);
              }}
            />
          )}
          {showEditModal && (
            <VideoEdit
              onShow={() => {
                setShowEditModal(false);
                setVideoId("");
              }}
              projectId={pId}
            />
          )}
          {showMediaModal && (
            <ViewMedia
              onShow={() => {
                setShowMediaModal(false);
                setViewMedia("");
              }}
              videoId={viewMediaId}
            />
          )}
        </DashboardLayout>
      ) : (
        <SignIn />
      )}
    </>
  );
}

export default VideoList;
