import { Icon } from "@mui/material";
import MDPagination from "components/MDPagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "store/users";
import { fetchVideos } from "store/video";
import { fetchProjects } from "store/project";
import { useLocation } from "react-router-dom";
function Pagination(props) {
  const { videos } = useSelector((state) => state);
  const { videoFilters } = useSelector((state) => state?.videos);
  // obj , type
  const dispatch = useDispatch();
  const getDispatchData = async (url) => {
    switch (props.type) {
      case "fetchUsers":
        await dispatch(fetchUsers(null, url));
        break;
      case "fetchProjects":
        await dispatch(fetchProjects(null, url));
        break;
      case "fetchVideos":
        await dispatch(
          fetchVideos(videoFilters, url)
        );
        break;
      default:
        console.log("Invalid Request");
    }
  };
  return (
    <>
      <MDPagination variant="gradient">
        {props?.object?.links &&
          props?.object?.links.map((obj, key) => (
            <div key={key}>
              {obj.label === "pagination.previous" && obj.url && (
                <MDPagination
                  item
                  key={`${props.type}_${key}`}
                  onClick={(e) => getDispatchData(obj.url)}
                  value={obj.url}
                >
                  <Icon>keyboard_arrow_left</Icon>
                </MDPagination>
              )}
              {obj.label !== "pagination.previous" &&
                obj.label !== "pagination.next" && (
                  <MDPagination
                    item
                    key={`${props.type}_${key}`}
                    onClick={(e) => getDispatchData(e.target.value)}
                    value={obj.url}
                    active={obj.active}
                  >
                    {obj.label}
                  </MDPagination>
                )}
              {obj.label === "pagination.next" && obj.url && (
                <MDPagination
                  item
                  key={`${props.type}_${key}`}
                  onClick={(e) => getDispatchData(obj.url)}
                  value={obj.url}
                >
                  <Icon>keyboard_arrow_right</Icon>
                </MDPagination>
              )}
            </div>
          ))}
      </MDPagination>
    </>
  );
}
export default Pagination;
