// Material Dashboard 2 React Components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects } from "store/project";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from "components/Common/Constants";

function Filter(props) {
    const { assignee } = useSelector(state => state.projects);
    const { auth } = useSelector(state => state);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            filter_title: "",
            filter_assignee_id: ""
        },
        // validationSchema: Yup.object({
        //     filter_title: Yup.string().required(`${props.label} is required`)
        // }),
        onSubmit: async (values) => {
            props?.setFilterData(values)
            await dispatch(fetchProjects(values));
        }
    });
    const resetData = async () => {
        formik.resetForm();
        await dispatch(fetchProjects());
    }
    // Add min height for select dropdown menu along with scrolling
    const MenuProps = {
        classes: { paper: "select-filter" },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
            },
        },
    };
    return (
        <>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                <MDBox mt={4} mb={1}>
                    <MDInput type="text" label={props.label}
                        name={props.name}
                        value={formik.values.filter_title}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />&nbsp;
                    {auth.user?.is_lead === 'yes' && <FormControl>
                        <InputLabel id="demo-simple-select-label">User</InputLabel>
                        <Select
                            sx={{ minWidth: 185, minHeight: 44 }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="User"
                            name="filter_assignee_id"
                            value={formik.values.filter_assignee_id}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // MenuProps={MenuProps}
                        >
                            <MenuItem value=''>
                                Select User
                            </MenuItem>
                            {assignee.map((data) => (
                                <MenuItem value={data.id}
                                    key={data.id}
                                >
                                    {data.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>} &nbsp;
                    <MDButton variant="gradient" color="info" type="submit">
                        Search
                    </MDButton>&nbsp;
                    <MDButton variant="gradient" type="reset" color="primary" onClick={resetData}>
                        Reset
                    </MDButton>
                </MDBox>
            </MDBox>
        </>
    )
}
export default Filter;