// react-routers components

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles

import UserEdit from "layouts/User/Edit";
import { useState } from "react";
import ChangeUserPasswordModal from "layouts/User/ChangerUserPassword";

function ProfileInfoCard({ title, description, info, action, shadow }) {
  const labels = [];
  const values = [];
  
  const [showEditModal, setShowEditModal] = useState(false);
  const [showChangeUserPasswordModal, setShowChangeUserPasswordModal] = useState(false);
  
  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));  
  return (
    <>
      <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>

        </MDBox>
        <MDBox p={2}>
          <MDBox>
            {renderItems}
            <MDBox display="flex" py={1} pr={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {/* social: &nbsp; */}
              </MDTypography>
              {/* {renderSocial} */}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {showEditModal && <UserEdit onShow={() => { setShowEditModal(false); }} userId={action.id} />}
      {showChangeUserPasswordModal && <ChangeUserPasswordModal title="Change User Password" onShow={() => { setShowChangeUserPasswordModal(false); }} userId={users.data[user].id} />}
    </>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    id: PropTypes.string,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
