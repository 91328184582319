import { useState } from "react";
import { useDispatch } from 'react-redux'
import { login } from '../../../store/auth'

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// validation
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Alert, CircularProgress } from "@mui/material";
import { getToast } from "toaster";

function Basic() {
  // const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  // validation
  const formik = useFormik({
    initialValues: {
      Login: {
        email: "",
        password: ""
      }
    },
    validationSchema: Yup.object({
      Login: Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        password: Yup.string().required("Password is required")
      })
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let res = await dispatch(login({ email: values.Login.email, password: values.Login.password }));
      if (res) {
        toast.error('Invalid Email/Password!', getToast(5000));
      } else {
        Navigate('/dashboard');
        toast.success('Logged Successfully!', getToast(5000));
      }
      setLoading(false);
    }
  });

  // useEffect(() => {
  //   formik.setFieldValue('email', '', true);
  // }, []);
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Media Manager
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
            <MDBox mb={2}>
              <MDInput type="email" label="Email"
                name="Login.email"
                value={formik.values.Login.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />
              {formik.errors.Login?.email && formik.touched.Login?.email &&
                <Alert severity="error" sx={{
                  height: 50
                }}><small>{formik.errors.Login.email}</small></Alert>
              }
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password"
                name="Login.password"
                value={formik.values.Login.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />
              {formik.errors.Login?.password && formik.touched.Login?.password &&
                <Alert severity="error" sx={{
                  height: 50
                }}><small>{formik.errors.Login.password}</small></Alert>
              }
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" type="submit" color="info" disabled={loading} fullWidth>
                sign in
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'secondary',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
