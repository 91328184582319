import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import Swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { Typography } from "@mui/material";
import { deleteAllProjects } from "store/project";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects } from "store/project";

// This component is responsible for rendering select buttons and handling delete functionality
const SelectBtn = ({ selectRows, selectAllPage, TotalRecords, filterData }) => {
  // Using Redux hooks to access state and dispatch actions
  const { projects } = useSelector((state) => state.projects);
  const dispatch = useDispatch();

  // Function to handle selecting/deselecting all pages
  const handleSelectAllPages = () => {
    selectAllPage?.setSelectAllPages((prev) => !prev);
    selectRows?.setSelectedProjects([]);
  };

  // Function to handle deleting selected projects
  const handleDeleteSelected = (id) => {
    if (id) {
      Swal({
        title: `Are you sure you want to delete ${
          selectAllPage?.selectAllPages
            ? parseInt(TotalRecords) - selectRows?.selectedProjects?.length
            : selectRows?.selectedProjects?.length
        } project(s) ?`,
        text: "Do you want to delete the selected Project ?",
        icon: "warning",
        buttons: {
          cancel: "Cancel!",
          ok: {
            text: "Yes, delete it!",
          },
        },
        dangerMode: true,
      }).then(async (confirm) => {
        if (confirm) {
          // Dispatching action to delete projects
          let res = await dispatch(
            deleteAllProjects(
              selectAllPage?.selectAllPages,
              selectRows?.selectedProjects,
              filterData
            )
          );
          if (res) {
            // Clearing selection and fetching updated projects after deletion
            selectAllPage?.setSelectAllPages(false);
            selectRows?.setSelectedProjects([]);
            Swal("Poof! project(s) has been deleted !", {
              icon: "success",
            });
            await await dispatch(fetchProjects());
          }
        } else {
          Swal("You have cancelled operation!");
        }
      });
    }
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
      <Grid item>
        <MDButton
          onClick={handleSelectAllPages}
          variant="outlined"
          color="info"
          size="small"
          startIcon={<CheckIcon />}
          disabled={projects?.data?.length === 0}
        >
          {selectAllPage?.selectAllPages
            ? "Deselect All"
            : "Select All"}
        </MDButton>
      </Grid>
      {(selectRows?.selectedProjects?.length > 0 ||
        selectAllPage?.selectAllPages) && (
        <>
          <Grid item>
            <MDButton
              onClick={handleDeleteSelected}
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              size="small"
            >
              Delete
            </MDButton>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              gutterBottom
              color="textSecondary"
              pt={1}
            >
              <>
                {selectAllPage?.selectAllPages
                  ? parseInt(TotalRecords) -
                    selectRows?.selectedProjects?.length
                  : selectRows?.selectedProjects?.length}{" "}
                Record
                {selectRows?.selectedProjects?.length > 1 ? "s" : ""} Selected
              </>
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SelectBtn;
