// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Data
import MDButton from "components/MDButton";

import { Modal } from "react-bootstrap";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addProject } from "store/project";
import { fetchProjects } from "store/project";
import { useState } from "react";
import { Alert, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getToast } from "toaster";

function ProjectAdd({ onShow }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { assignee } = useSelector(state => state.projects);
  // validation
  const formik = useFormik({
    initialValues: {
      Add: {
        title: "",
        description: "",
        assignee_id: []
      }
    },
    validationSchema: Yup.object({
      Add: Yup.object({
        title: Yup.string().required("Title is required"),
        description: Yup.string()
          .required("Description is required"),
        assignee_id: Yup.array().min(1,"Select Assignee")
      })
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let res = await dispatch(addProject({
        title: values.Add.title, description: values.Add.description, assignee_id: values.Add.assignee_id
      }));
      if (res.data.error) {
        toast.error(res.data.error, getToast(5000));
      } else {
        toast.success('Project Saved Successfully!', getToast(5000));
        onShow();
        await dispatch(fetchProjects());
      }
      setLoading(false);
    }
  });
  return (
    <>
      <Modal show={onShow} onHide={() => onShow()} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput type="text" label="Title"
                  name="Add.title"
                  value={formik.values.Add.title}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                />
                {formik.errors.Add?.title && formik.touched.Add?.title && (
                  <Alert severity="error" sx={{
                    height: 50
                  }}><small>{formik.errors.Add.title}</small></Alert>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="text" label="Description"
                  name="Add.description"
                  value={formik.values.Add.description}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                  autoFocus={formik.errors.Add?.description ? true : false}
                />
                {formik.errors.Add?.description && formik.touched.Add?.description && (
                  <Alert severity="error" sx={{
                    height: 50
                  }}><small>{formik.errors.Add.description}</small></Alert>
                )}
              </MDBox>
              {assignee.length > 0 &&
                <FormControl sx={{ width: 420 }}>
                  <InputLabel id="demo-simple-select-label">Assignee</InputLabel>
                  <Select
                  multiple
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Assignee"
                    name="Add.assignee_id"
                    value={formik.values.Add.assignee_id}
                    onChange={formik.handleChange}
                    sx={{ height: 43, maxHeight : 500 }}
                  >
                    <MenuItem value='' disabled>
                      Select Assignee
                    </MenuItem>
                    {assignee?.map((data) => (
                      <MenuItem key={data.id} value={data.id}
                      >
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors.Add?.assignee_id && formik.touched.Add?.assignee_id && (
                    <Alert severity="error" sx={{
                      height: 50
                    }}><small>{formik.errors.Add.assignee_id}</small></Alert>
                  )}
                </FormControl>
              }
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" disabled={loading} type="submit">
                  Add
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'secondary',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </MDButton>&nbsp;
                <MDButton variant="gradient" color="primary" onClick={() => onShow()}>
                  Cancel
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProjectAdd;
