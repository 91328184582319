import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import Swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllVideos } from "store/video";
import { fetchVideos } from "store/video";

// This component is responsible for rendering select buttons and handling delete functionality
const SelectBtn = ({ selectRows, selectAllPage, TotalRecords }) => {
  // Using Redux hooks to access state and dispatch actions
  const { videos } = useSelector((state) => state?.videos);
  const dispatch = useDispatch();
  const filter = useSelector((state) => state?.videos?.searchObj);
  const { videoFilters } = useSelector((state) => state?.videos);
  const { auth } = useSelector((state) => state);
  // Function to handle selecting/deselecting all pages
  const handleSelectAllPages = () => {

    selectAllPage?.setSelectAllPages((prev) => { return !prev });
    selectRows?.setSelectedVideos([]);
  };

  // Function to handle deleting selected videos
  const handleDeleteSelected = (id) => {
    if (id) {
      Swal({
        title: `Are you sure you want to delete ${auth?.user?.is_lead == "yes" && (selectAllPage?.selectAllPages
          ? parseInt(TotalRecords) - selectRows?.selectedVideos?.length
          : selectRows?.selectedVideos?.length)
          } media file(s) ?`,
        text: "Do you want to delete the selected media file ?",
        icon: "warning",
        buttons: {
          cancel: "Cancel!",
          ok: {
            text: "Yes, delete it!",
          },
        },
        dangerMode: true,
      }).then(async (confirm) => {
        if (confirm) {
          // Dispatching action to delete videos
          let res = await dispatch(
            deleteAllVideos(
              selectAllPage?.selectAllPages,
              selectRows?.selectedVideos,
              videoFilters
            )
          );
          if (res) {
            // Clearing selection and fetching updated videos after deletion
            selectRows?.setSelectedVideos([]);
            selectAllPage?.setSelectAllPages(false);
            Swal("Poof! file(s) has been deleted !", {
              icon: "success",
            });
            await dispatch(fetchVideos(videoFilters));
          }
        } else {
          Swal("You have cancelled operation!");
        }
      });
    }
  };
  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
      <Grid item>
        <MDButton
          onClick={handleSelectAllPages}
          variant="outlined"
          color="info"
          size="small"
          startIcon={<CheckIcon />}
          disabled={videos?.data?.length === 0}
        >
          {auth?.user?.is_lead == "yes" ? selectAllPage?.selectAllPages
            ? "Deselect All"
            : "Select All" : selectAllPage?.selectAllPages
            ? "Deselect your all record"
            : "Select your all record"}
        </MDButton>
      </Grid>
      {(selectRows?.selectedVideos?.length > 0 ||
        selectAllPage?.selectAllPages) && (
          <>
            <Grid item>
              <MDButton
                onClick={handleDeleteSelected}
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                size="small"
              >
                Delete
              </MDButton>
            </Grid>
            {auth?.user?.is_lead == "yes" ? <Grid item>
              <Typography
                variant="body2"
                gutterBottom
                color="textSecondary"
                pt={1}
              >
                <>
                  {selectAllPage?.selectAllPages
                    ? parseInt(TotalRecords) - selectRows?.selectedVideos?.length
                    : selectRows?.selectedVideos?.length}{" "}
                  Record
                  {selectRows?.selectedVideos?.length > 1 ? "s" : ""} Selected
                </>
              </Typography>
            </Grid> : <></>}
          </>
        )}
    </Grid>
  );
};

export default SelectBtn;
