import { createSlice } from "@reduxjs/toolkit";
// import api from '../services/api';
import axios from "axios";
import Service from "Service";

// Set auth token to initialUser for auth state
const initialUser = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token"))
  : null;
// Slice
const slice = createSlice({
  name: "auth",
  initialState: {
    auth: initialUser,
    user: {},
  },
  reducers: {
    // Add user auth token to auth state and user data to user state
    loginSuccess: (state, action) => {
      state.auth = action.payload.access_token;
      state.user = action.payload.user;
      action.payload.access_token &&
        localStorage.setItem(
          "token",
          JSON.stringify(action.payload.access_token)
        );
    },
    // Empty auth and user state as well as remove token from local storage
    logoutSuccess: (state, action) => {
      state.auth = null;
      state.user = {};
      localStorage.removeItem("token");
    },
    // Add logged user data to user state after success login
    loggedUser: (state, action) => {
      state.user = action.payload;
    },
  },
});
export default slice.reducer;
// Actions
const { loginSuccess, logoutSuccess, loggedUser } = slice.actions;
export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      // Calling Login API
      const res = await axios.post(
        `${process.env.REACT_APP_BaseUrl}/login`,
        { email, password }
      );
      dispatch(loginSuccess(res.data));
    } catch (e) {
      // console.error(e.message);
      return e;
    }
  };
export const logout = () => async (dispatch) => {
  try {
    return dispatch(logoutSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
export const refreshToken = async () => {
  try {
    // Calling Refresh Token API
    const res = await axios.post(
      `${process.env.REACT_APP_BaseUrl}/refresh`,
      {},
      Service.config()
    );
    if (res.data) {
      localStorage.setItem("token", JSON.stringify(res.data.access_token));
    }
    return res.data;
  } catch (e) {
    console.error(e.message);
    // dispatch(logoutSuccess());
  }
};
export const loggedUserProfile = () => async (dispatch) => {
  try {
    // Calling User Profile API and in response logged user data will be set to user state
    const loggedUserData = await axios.get(
      `${process.env.REACT_APP_BaseUrl}/user-profile`,
      Service.config()
    );
    dispatch(loggedUser(loggedUserData.data));
  } catch (e) {
    // console.error(e.message);
    dispatch(logout());
  }
};
