import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Service from "Service";

const initialProject = [];

// Slice
const slice = createSlice({
  name: "projects",
  initialState: {
    projects: initialProject,
    assignee: [],
    projectFilter: [],
    selectedProject: null,
  },
  reducers: {
    // Add projects data to projects state
    fetch: (state, action) => {
      state.projects = action.payload.projects;
    },
    // Add project data to projectData state for update
    edit: (state, action) => {
      state.projectData = action.payload;
    },
    // Add assignee names to assignee state for user search dropdown
    projectFilter: (state, action) => {
      state.assignee = action.payload;
    },
    //
    projectFilter2: (state, action) => {
      state.projectFilter = action.payload;
    },
    //select project reducer
    selectProjectReducer: (state, action) => {
      state.selectedProject = action.payload;
    },
  },
});
export default slice.reducer;
// Actions
const { fetch, edit, projectFilter, projectFilter2, selectProjectReducer } =
  slice.actions;
export const fetchProjects = (filter, url, id) => async (dispatch) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    if (filter) {
      config["params"] = filter;
    }

    if (!url) {
      url = `${process.env.REACT_APP_BaseUrl}/projects`;
    }
    // Fetch Projects API
    const res = await axios.get(url, config);
    dispatch(fetch(res.data));
    return res?.data?.projects?.data;
  } catch (e) {
    return e.message;
  }
};

export const getProject = (projectId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BaseUrl}/project/${projectId}`,
      Service.config()
    );
    dispatch(edit(res.data.project));
  } catch (e) {
    return e.message;
  }
};

export const addProject = (projectData) => async (dispatch) => {
  try {
    // API for Add New Project
    const res = await axios.post(
      `${process.env.REACT_APP_BaseUrl}/projects`,
      projectData,
      Service.config()
    );
    // dispatch(fetch(res.data));
    return res;
  } catch (e) {
    return e.message;
  }
};

export const editProject = (projectId, projectData) => async (dispatch) => {
  try {
    // API for Update existing Project
    const res = await axios.post(
      `${process.env.REACT_APP_BaseUrl}/project-update/${projectId}`,
      projectData,
      Service.config()
    );
    return res;
  } catch (e) {
    return e.message;
  }
};

export const delProject = (projectId) => async (dispatch) => {
  try {
    // API for delete Project
    const res = await axios.post(
      `${process.env.REACT_APP_BaseUrl}/project-remove/${projectId}`,
      {},
      Service.config()
    );
    return res;
  } catch (e) {
    return e;
  }
};
// Get All Users List for Project Assignee [lead access]
export const projectAssignee = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BaseUrl}/projects-filters`,
      Service.config()
    );
    dispatch(projectFilter(res.data.assignee_list));
  } catch (e) {
    return e.message;
  }
};

export const fetchProjectFilters = () => async (dispatch) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    // Fetch Projects API
    const res = await axios.get(
      `${process.env.REACT_APP_BaseUrl}/videos-filters`,
      config
    );
    dispatch(projectFilter2(res.data.project_list));
    return res;
  } catch (e) {
    return e.message;
  }
};

export const selectProject = (id) => async (dispatch) => {
  try {
    dispatch(selectProjectReducer(id));
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };

    // fetch Filtered project Media
    if (id) {
      const res = await axios.get(
        `${process.env.REACT_APP_BaseUrl}/videos?filter_project_id=${id}`,
        config
      );
      return res?.data?.videos?.total;
    }
  } catch (e) {
    return e.message;
  }
};

// Function to create URL based on provided filters
function createUrl(filter) {
  let url = `${process.env.REACT_APP_BaseUrl}/project-bulk-remove`;
  // Check if both filter_title and filter_project_id are present
  if (filter?.filter_title && filter?.filter_project_id) {
    url += `?filter_title=${filter?.filter_title}&filter_project_id=${filter?.filter_project_id}`;
    return url;
  }
  // Check if only filter_title is present
  if (filter?.filter_title) {
    url += `?filter_title=${filter?.filter_title}`;
    return url;
  }
  // Check if only filter_project_id is present
  if (filter?.filter_project_id) {
    url += `?filter_project_id=${filter?.filter_project_id}`;
    return url;
  }
  // Return the URL as is if no filters are provided
  return url;
}

// Action to delete all Projects
export const deleteAllProjects =
  (selectAllPage, selectRows, filter) => async (dispatch) => {
    // Convert selectRows array to comma-separated string
    const commaSeparatedString = JSON.stringify(selectRows)?.replace(
      /[\[\]']/g,
      ""
    );
    try {
      const res = await axios.post(
        createUrl(filter), // Call createUrl function to get the URL with applied filters
        {
          selected: selectAllPage ? "ALL" : commaSeparatedString,
        },
        Service.config()
      );
      return res;
    } catch (e) {
      console.error(e.message);
      return e;
    }
  };
