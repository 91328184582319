// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Data
import MDButton from "components/MDButton";

import { Modal } from "react-bootstrap";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addVideo, uploadVideo } from "store/video";
import { useEffect, useState } from "react";
import {
  Alert,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { ITEM_HEIGHT, ITEM_PADDING_TOP } from "components/Common/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchVideos } from "store/video";
import { useForm } from "react-hook-form";
import { delVideo } from "store/video";
import { getToast } from "toaster";

const schema = Yup.object({
  Add: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Title is required"),
      filename: Yup.string().required("File is required"),
    })
  ),
  project_id: Yup.string().required("Please select a project"),
});

function VideoAdd({ onShow, pId }) {
  const dispatch = useDispatch();
  const [projectId, setProjectId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { projectFilter } = useSelector((state) => state.projects);
  const { selectedProject } = useSelector((state) => state.projects);
  const search = useSelector((state) => state.videos.searchObj);
  const [fileValue, setFileValue] = useState(null);
  const [mediaCount, setMediaCount] = useState(1);
  const [mediaData, setMediaData] = useState([
    {
      title: "",
      file: null,
      project_id: search.filter_project_id || projectId,
    },
  ]);
  // Init, Processing, Uploaded, Failed
  const [uploadStatus, setUploadStatus] = useState("Upload");
  // Add min height for select dropdown menu along with scrolling
  const MenuProps = {
    classes: { paper: "select-project" },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const addMediaField = () => {
    setMediaCount(mediaCount + 1);
    setMediaData([
      ...mediaData,
      {
        title: "",
        file: null,
        project_id:
          search.filter_project_id || getValues("project_id") || projectId,
      },
    ]);
  };

  const handleTitleChange = (index, value) => {
    const updatedMediaData = [...mediaData];
    updatedMediaData[index].title = value;
    setMediaData(updatedMediaData);
  };
  const handleProjectIdChange = (id) => {
    const updatedMediaData = [...mediaData];
    updatedMediaData[index].project_id = value || search?.filter_project_id;
    setMediaData(updatedMediaData);
  };

  const handleFileChange = (index, file) => {
    const updatedMediaData = [...mediaData];
    updatedMediaData[index].file = file;
    setMediaData(updatedMediaData);
  };

  let project_id = "";

  if (selectedProject && !search.filter_project_id) {
    project_id = selectedProject;
  }

  if (search && search.filter_project_id) {
    project_id = search.filter_project_id;
  }

  if (pId) {
    project_id = pId;
  }

  useEffect(() => {
    setValue("project_id", project_id);
  }, []);

  //Get in built function from useForm
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const handleSubmitForm = (values) => {
    mediaData?.map(async (_data, i) => {
      var { name, file: fileValue, project_id } = _data;
      if (fileValue) {
        setValue(`Add.[${values.Add.length - i - 1}].isLoading`, true);
        setLoading(true);
        setUploadStatus("Processing");
        let formData = new FormData();
        formData.append("image", fileValue);
        let upload = await uploadVideo(formData);
        if (upload.status === 200) {
          setValue(
            `Add.[${i}].filename`,
            upload.data.file
          );
          let res = await dispatch(
            addVideo({
              title: values.Add[i].title,
              filename: upload.data.file,
              project_id: values?.project_id,
            })
          );
          if (res.message) {
            setValue(`Add.[${values.Add.length - i - 1}].status`, "Failed");
            setUploadStatus("Failed");
            toast.error(res.message, getToast(2000));
          } else {
            setValue(`Add.[${values.Add.length - i - 1}].status`, "Uploaded");
            setValue(`Add.[${values.Add.length - i - 1}].id`, res.data.videoId);
            setUploadStatus("Uploaded");
            setTimeout(async function () {
              toast.success("Video Uploaded Successfully!", getToast(2000));
              onShow();
              setMediaCount((state) => state + 1);
              await dispatch(
                fetchVideos(
                  search.filter_project_id
                    ? search
                    : { filter_project_id: selectedProject }
                )
              );
            }, 1000);
          }
        } else {
          toast.error("Failed To Upload", getToast(2000));
          setUploadStatus("Failed");
        }
        setValue(`Add.[${values.Add.length - i - 1}].isLoading`, false);
        setLoading(false);
      }
    });
  };

  //Define function to handle form validations errors.
  const handleError = (errors) => {
    console.log("Errors = ", errors);
    // setStep((step || 0) + 1);
  };

  const deleteMedia = async (dataIndex) => {
    let data = getValues();
    const filteredUploadedMedia = data.Add.filter((data, i) => i !== dataIndex);
    setValue("Add", filteredUploadedMedia);
    setMediaCount((state) => state - 1);
  };

  return (
    <>
      <Modal
        show={onShow}
        onHide={() => onShow()}
        centered
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Upload Video(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox
            component="form"
            style={{ marginTop: "40px" }}
            role="form"
            onSubmit={handleSubmit(handleSubmitForm, handleError)}
          >
            <MDBox pt={4} pb={3} px={3}>
              {!pId && (
                <FormControl
                  sx={{
                    maxWidth: 1062,
                    minWidth: "100%",
                    marginLeft: "0px",
                    marginBottom: "30px",
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: -10 }}
                  >
                    Project
                  </InputLabel>
                  <Select
                    variant="standard"
                    sx={{ height: 20, maxHeight: 500 }}
                    labelId="demo-simple-select-standard-label"
                    name="project_id"
                    id="demo-simple-select-standard"
                    value={project_id || getValues("project_id")}
                    {...register(`project_id`)}
                    onChange={(event) => {
                      setProjectId(
                        event.target.value || getValues("project_id")
                      );
                      setValue("project_id", event.target.value);
                    }}
                    // MenuProps={MenuProps}
                  >
                    <MenuItem value="">Select Project</MenuItem>
                    {projectFilter?.map((project) => (
                      <MenuItem value={project.id} key={project.id}>
                        {project.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.project_id?.message && (
                    <Alert
                      severity="error"
                      sx={{
                        height: 50,
                      }}
                    >
                      <small>{errors.project_id.message}</small>
                    </Alert>
                  )}
                </FormControl>
              )}
              {Array.from({ length: mediaCount }).map((_, index) => {
                const realIndex = index + 1;
                return (
                  <>
                    <MDBox gap={2} pb={4} flexWrap="wrap" display="flex">
                      <MDBox
                        sx={{
                          // maxWidth: 1062,
                          minWidth: "100%",
                          marginLeft: "0px",
                        }}
                        style={{
                          minWidth: mediaCount > 1 ? "47.2%" : "49%",
                        }}
                      >
                        <FormControl
                          style={{
                            minWidth: "100%",
                          }}
                        >
                          <TextField
                            id="standard-basic"
                            label="Title"
                            // variant="outlined"
                            name="title"
                            {...register(`Add[${index}].title`)}
                            onChange={(e) => {
                              setValue(`Add[${index}].title`, e.target.value);
                              handleTitleChange(index, e.target.value);
                            }}
                            fullWidth
                            style={{padding : 0, height : 43,   minWidth: "100%" }}
                          />
                          {errors?.Add?.[index]?.title?.message && (
                            <Alert
                              severity="error"
                              sx={{
                                height: 50,
                              }}
                            >
                              <small>
                                {errors?.Add?.[index]?.title?.message}
                              </small>
                            </Alert>
                          )}
                        </FormControl>
                      </MDBox>
                      <MDBox
                        justifyContent="around"
                        sx={{
                          // maxWidth: 1062,
                          minWidth: "100%",
                          marginLeft: "0px",
                        }}
                        style={{
                          minWidth: mediaCount > 1 ? "47.2%" : "49%",
                        }}
                      >
                        <FormControl
                          style={{
                            minWidth: "100%",
                            marginTop : "3px"
                          }}
                        >
                          <input
                            size={"15"}
                            type="file"
                            label="Upload Video"
                            variant="standard"
                            className="form-control"
                            accept="video/*, images/*, .pdf, .jpg, .png, .jpeg"
                            name={`Add[${index}].filename`}
                            // onBlur={formik.handleBlur}
                            onChange={async (event) => {
                              setValue(
                                `Add[${index}].filename`,
                                event?.target?.files[0]?.name
                              );
                              handleFileChange(index, event.target.files[0]);
                              setFileValue(event.target.files[0]);
                              await trigger(`Add[${index}].filename`);
                            }}
                            fullWidth
                          />
                          {errors?.Add?.[index]?.filename?.message && (
                            <Alert
                              severity="error"
                              sx={{
                                height: 50,
                              }}
                            >
                              <small>
                                {errors?.Add?.[index]?.filename?.message}
                              </small>
                            </Alert>
                          )}
                        </FormControl>
                      </MDBox>
                      <MDBox
                        display="flex"
                        justifyContent="end"
                        sx={{
                          minWidth: "100%",
                          marginLeft: "0px",
                        }}
                        style={{
                          cursor: mediaCount > 1 ? "pointer" : "not-allowed",
                          marginTop: 10,
                          display: mediaCount <= 1 ? "none" : "",
                          minWidth: "2%",
                        }}
                      >
                        <Icon
                          fontSize="medium"
                          disabled={mediaCount <= 1}
                          onClick={() => {
                            deleteMedia(index);
                          }}
                        >
                          delete
                        </Icon>
                      </MDBox>
                    </MDBox>
                  </>
                );
              })}
              <MDBox mt={4} mb={1} display="flex" flexWrap="wrap" gap={4}>
                <MDBox
                  style={{
                    // marginLeft: "10px",
                    display: "flex",
                    width: "95px",
                  }}
                >
                  <FormControl>
                    <MDButton
                      variant="gradient"
                      color={
                        getValues(`Add[${mediaCount - 1}].status`) ===
                        "Uploaded"
                          ? "success"
                          : "info"
                      }
                      disabled={loading}
                      type="submit"
                      // onClick={() => handleSubmitForm(getValues())}
                      onSubmit={() => {
                        handleSubmit(handleSubmitForm, handleError);
                        // handleSubmitForm(getValues());
                      }}
                    >
                      {getValues(`Add[${mediaCount - 1}].status`)
                        ? getValues(`Add[${mediaCount - 1}].status`)
                        : "Upload"}
                      {getValues(`Add[${mediaCount - 1}].isLoading`) && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "secondary",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </MDButton>
                  </FormControl>
                </MDBox>
                <MDButton
                  variant="gradient"
                  color="success"
                  disabled={loading}
                  onClick={async () => {
                    addMediaField();
                    // await dispatch(fetchVideos(search.filter_project_id ? search : { filter_project_id: selectedProject }));
                  }}
                >
                  Add More Media
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="primary"
                  disabled={loading}
                  onClick={async () => {
                    onShow();
                    // await dispatch(fetchVideos(search.filter_project_id ? search : { filter_project_id: selectedProject }));
                  }}
                >
                  Close
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VideoAdd;
