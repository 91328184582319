import Moment from "moment";
import {
  videoExtension,
  imageExtension,
  audioExtension,
} from "components/Common/Constants";
const Helpers = {
  // Format : Sep 23rd 22
  formatDate: function (date, format) {
    if (date) {
      const formatDate = Moment(date).format(format ? format : "MMM Do YY");
      return formatDate;
    }
  },
  formatDateFilter: function (date) {
    const formatDate = Moment(date).format("YYYY-MM-DD");
    if (formatDate === "Invalid date") {
      return null;
    }
    return formatDate;
  },
  //  Check file type
  fileType: function (filename) {
    if (filename) {
      let type = "";
      const fType = filename.split(".");
      if (fType) {
        if (videoExtension.includes(fType[1])) {
          type = "video";
        }
        if (audioExtension.includes(fType[1])) {
          type = "audio";
        }
        if (imageExtension.includes(fType[1])) {
          type = "image";
        }
      }
      // return fType[fType.length - 1];
      return type;
    }
  },

  //function is going to use in abbreviate long text into flexible length
  abbreviateLongText: function (text = "", minLength = 45) {
    return text.length > minLength
      ? text.slice(0, minLength).concat("...")
      : text;
  },
};

export default Helpers;
