import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from 'react-redux';
import store from './store/index';

import { ToastContainer } from 'react-toastify';
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import "bootstrap/dist/css/bootstrap.min.css";
// Global Custom Css
import './commonComponents/Css/Custom.css';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <MaterialUIControllerProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <App />
      </MaterialUIControllerProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
