// Material Dashboard 2 React Components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { fetchUsers } from "store/users";

function Filter(props) {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            filter_name: ""
        },
        validationSchema: Yup.object({
            filter_name: Yup.string().required(`${props.label} is required`)
        }),
        onSubmit: async (values) => {
            await dispatch(fetchUsers(values));
        }
    });

    const resetData = async () => {
        formik.resetForm()
        await dispatch(fetchUsers());
    }

    return (
        <>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                <MDBox mt={4} mb={1}>
                    <MDInput type="text" label={props.label}
                        name={props.name}
                        value={formik.values.filter_name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />&nbsp;
                    <MDButton variant="gradient" color="info" type="submit">
                        Search
                    </MDButton>&nbsp;
                    <MDButton variant="gradient" type="reset" color="primary" onClick={resetData}>
                        Reset
                    </MDButton>
                </MDBox>
            </MDBox>
        </>
    )
}

export default Filter;