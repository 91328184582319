// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Data
import MDButton from "components/MDButton";

import { Modal } from "react-bootstrap";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { editUser } from "store/users";
import { fetchUsers } from "store/users";
import { Alert } from "@mui/material";
import { getToast } from "toaster";

function UserEdit({ onShow, title }) {
    const { userData } = useSelector(state => state.users);
    const dispatch = useDispatch();
    let initialValues = {};

    if (userData) {
        initialValues = {
            name: userData.name,
            email: userData.email
        }
    }
    // validation
    const formik = useFormik({
        initialValues: {
            Edit: initialValues
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            Edit: Yup.object({
                name: Yup.string().required("Name is required"),
                email: Yup.string()
                    .email("Invalid email address")
                    .required("Email is required")
            })
        }),
        onSubmit: async (values) => {
            let res = await dispatch(editUser(userData.id, values.Edit));
            if (res.data.message) {
                toast.success(res.data.message, getToast(5000));
                onShow();
                // call fetch api for user list
                await dispatch(fetchUsers());
            } else {
                toast.error('Error, Try Again!', getToast(5000));
                onShow();
            }
        }
    });
    return (
        <>
            <Modal show={onShow} onHide={() => onShow()} centered={!title && true} backdrop="static">
                <Modal.Header closeButton={!title && true}>
                    <Modal.Title>{title ? title : 'Update User'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MDBox pt={4} pb={3} px={3}>
                        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                            <MDBox mb={2}>
                                <MDInput type="text" label="Name" variant="standard"
                                    name="Edit.name"
                                    value={formik.values.Edit.name}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    fullWidth
                                />
                                {formik.errors.Edit?.name && formik.touched.Edit?.name && (
                                    <Alert severity="error" sx={{
                                        height: 50
                                    }}><small>{formik.errors.Edit.name}</small></Alert>
                                )}
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput type="email" label="Email" variant="standard"
                                    name="Edit.email"
                                    value={formik.values.Edit.email}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    autoFocus={formik.errors.Edit?.email ? true : false}
                                />
                                {formik.errors.Edit?.email && formik.touched.Edit?.email && (
                                    <Alert severity="error" sx={{
                                        height: 50
                                    }}><small>{formik.errors.Edit.email}</small></Alert>
                                )}
                            </MDBox>
                            {/* <MDBox mb={2}>
                                <MDInput type="password" label="Password" variant="standard"
                                    name="Edit.password"
                                    value={formik.values.Edit.password}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    fullWidth
                                // autoFocus= {formik.errors.Edit ? 'autoFocus' : null} 
                                />
                                {formik.errors.Edit?.password && formik.touched.Edit?.password && (
                                    <Alert severity="error" sx={{
                                        height: 50
                                    }}><small>{formik.errors.Edit.password}</small></Alert>
                                )}
                            </MDBox> */}
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" color="info" type="submit">
                                    Update
                                </MDButton>&nbsp;
                                <MDButton variant="gradient" color="primary" onClick={() => onShow()}>
                                    Cancel
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default UserEdit;
