// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DataTable from "commonComponents/Tables/DataTable";

// Data
import usersTableData from "layouts/tables/data/usersTableData";
import MDButton from "components/MDButton";
import { useMemo, useState } from "react";
import UserAdd from "./Add";
import UserEdit from "./Edit";
import Filter from "./Filter";
import { useSelector } from "react-redux";
import Pagination from "components/Custom/Pagination";
import ChangeUserPasswordModal from "./ChangerUserPassword";

/**
 * Component includes : User List, Add/Edit/Delete actions for users management
 * Filter and Pagination
 * @returns Users list in DataTables
 */

function UserList() {
  const { columns, rows, userId, setUserId, loader, showChangeUserPasswordModal, setShowChangeUserPasswordModal, passwordUserId } = usersTableData();
  const [show, setShow] = useState(false);
  const { users } = useSelector(state => state.users);
  const [showEditModal, setShowEditModal] = useState(false);
  // const [showChangeUserPasswordModal, setShowChangeUserPasswordModal] = useState(true);
  const handleShow = () => setShow(true);
  useMemo(() => {
    // if(passwordModel){
    //   setShowChangeUserPasswordModal()
    // }
    if (userId) {
      setShowEditModal(true);
    }
  }, [userId]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Filter label="Search By Name" name="filter_name" />
        </Grid>
        <Grid item xs={4} mt={4}>
          <MDButton style={{ float: 'right' }} variant="outlined" color="info" onClick={handleShow}>Add User</MDButton>
        </Grid>
      </Grid>
      <MDBox pt={6} pb={3} >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Manage Users
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  noEndBorder
                  loader={loader}
                  setShowChangeUserPasswordModal={() => setShowChangeUserPasswordModal(true)}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox >
      <Pagination
        key="fetchUsers"
        type="fetchUsers"
        object={users}
      />
      {show && <UserAdd onShow={() => setShow(false)} />}
      {showEditModal && <UserEdit onShow={() => { setShowEditModal(false); setUserId('') }} userId={userId} />}
      {showChangeUserPasswordModal && <ChangeUserPasswordModal title={"Change User Password"} onShow={() => { setShowChangeUserPasswordModal(); }} userId={passwordUserId} />}
    </>
  );
}

export default UserList;
