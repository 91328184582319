

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDAvatar
import MDAvatarRoot from "components/MDAvatar/MDAvatarRoot";

function stringToColor(string) {
  let stringUniqueHash = [...string].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 270}, 40%, 40%)`;
}

function stringAvatar(name) {
  var newName = name.split(' ').length === 2 ? `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}` : `${name?.split(' ')[0][0]}`;
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: newName.toUpperCase(),
  };
}

const MDAvatar = forwardRef(({ bgColor, size, shadow, name, ...rest }, ref) => {
  return (
  <MDAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...stringAvatar(name.trim())} {...rest} />
)});

// Setting default values for the props of MDAvatar
MDAvatar.defaultProps = {
  bgColor: "transparent",
  size: "md",
  shadow: "none",
};

// Typechecking props for the MDAvatar
MDAvatar.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
};

export default MDAvatar;
