import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import UserList from '../User/List';
import SignIn from "layouts/authentication/sign-in";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import ProfileInfoCard from "commonComponents/Cards/InfoCards/ProfileInfoCard";


// Dashboard components

function Dashboard() {
  const { auth } = useSelector(state => state);
  const { users } = useSelector(state => state);
  const { userData } = useSelector(state => state.users);
  return (
    <>
      {auth ? <DashboardLayout>
        <DashboardNavbar />
        {auth.user?.is_lead === 'yes' && <UserList />}
        {auth.user?.is_lead === 'no' &&
          <>
            < Divider orientation="vertical" sx={{ ml: -2, mr: 1, mt:6 }} />
            <ProfileInfoCard
              title="profile information"
              info={{
                Name: userData?.id && users?.users?.data?.length === 1 ? users?.users?.data[0].name : auth.user.name,
                email: userData?.id && users?.users?.data?.length === 1 ? users.users.data[0].email : auth.user.email
              }}
              action={{ id: auth.user.id, tooltip: "Edit Profile" }}
              shadow={false}
            />
            <Divider orientation="vertical" sx={{ mx: 0 }} />
          </>
        }
      </DashboardLayout> : <SignIn />}
    </>
  );
}

export default Dashboard;
