// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Data
import MDButton from "components/MDButton";

import { Modal } from "react-bootstrap";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects } from "store/project";
import { editProject } from "store/project";
import { Alert, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getToast } from "toaster";

function ProjectEdit({ onShow }) {
    const { projectData } = useSelector(state => state.projects);
    const { auth } = useSelector(state => state);
    const { assignee } = useSelector(state => state.projects);
    const dispatch = useDispatch();
    let initialValues = {};

    if (projectData) {
        initialValues = {
            title: projectData.title,
            description: projectData.description,
            assignee_id: projectData?.assignee_id ? projectData.assignee_id : []
        }
    }
    // validation
    const formik = useFormik({
        initialValues: {
            Edit: initialValues
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            Edit: Yup.object({
                title: Yup.string().required("Title is required"),
                description: Yup.string()
                    .required("Description is required"),
                assignee_id: Yup.array().min(1, "Select Assignee")
            })
        }),
        onSubmit: async (values) => {
            let res = await dispatch(editProject(projectData.id, values.Edit));
            if (res.data.message) {
                toast.success(res.data.message, getToast(5000));
                onShow();
                await dispatch(fetchProjects());
            } else {
                toast.error('Error, Try Again!', getToast(5000));
                onShow();
            }
        }
    });
    return (
        <>
            <Modal show={onShow} onHide={() => onShow()} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Update Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {auth?.user?.is_lead === 'yes' && projectData?.assignee?.name && <small>Created By : {projectData?.assignee?.name}</small>}
                    <MDBox pt={4} pb={2} px={2}>
                        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                            <MDBox mb={2}>
                                {/* <MDInput type="text" label="Title" variant="standard"
                                    name="Edit.title"
                                    value={formik.values.Edit.title}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    fullWidth
                                /> */}
                                <MDInput type="text" label='Title'
                                    name="Edit.title"
                                    value={formik.values.Edit.title}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    fullWidth
                                />
                                {formik.errors.Edit?.title && formik.touched.Edit?.title && (
                                    <Alert severity="error" sx={{
                                        height: 50
                                    }}><small>{formik.errors.Edit.title}</small></Alert>
                                )}
                            </MDBox>
                            <MDBox mb={2}>
                                {/* <MDInput type="text" label="Email" variant="standard"
                                    name="Edit.description"
                                    value={formik.values.Edit.description}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    autoFocus={formik.errors.Edit?.description ? true : false}
                                /> */}
                                <MDInput type="text" label='Description'
                                    name="Edit.description"
                                    value={formik.values.Edit.description}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    autoFocus={formik.errors.Edit?.description ? true : false}
                                />
                                {formik.errors.Edit?.description && formik.touched.Edit?.description && (
                                    <Alert severity="error" sx={{
                                        height: 50
                                    }}><small>{formik.errors.Edit.description}</small></Alert>
                                )}
                            </MDBox>
                            {assignee.length > 0 &&
                                <FormControl sx={{ width: 435 }}>
                                    <InputLabel id="demo-simple-select-label">Assignee</InputLabel>
                                    <Select
                                        multiple
                                        // sx={{  minWidth:420, maxWidth: 500, minHeight: 43 }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Assignee"
                                        name="Edit.assignee_id"
                                        value={formik.values.Edit.assignee_id}
                                        sx={{ height: 43, maxHeight : 500 }}
                                        // onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value='' disabled >
                                        Select Assignee
                                        </MenuItem>
                                        {assignee?.map((data) => (
                                            <MenuItem key={data.id} value={data.id}
                                            >
                                                {data.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formik.errors.Edit?.assignee_id && (
                                        <Alert severity="error" sx={{
                                            height: 50
                                        }}><small>{formik.errors.Edit.assignee_id}</small></Alert>
                                    )}
                                </FormControl>
                            }
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" color="info" type="submit">
                                    Update
                                </MDButton>&nbsp;
                                <MDButton variant="gradient" color="primary" onClick={() => onShow()}>
                                    Cancel
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ProjectEdit;
