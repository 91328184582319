const Service = {
    // Set Config for API
    config: function () {
        let config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        };
        return config;
    }
}

export default Service;