// @mui material components
import { Checkbox } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

import Helpers from "Helpers";
import Projects from "layouts/rtl/components/Projects";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectProject } from "store/project";
import { delProject, fetchProjects, getProject } from "store/project";
// import { fetchVideos } from "store/video";
import Swal from "sweetalert";

export default function Data() {
  const navigate = useNavigate();

  const { auth } = useSelector((state) => state);
  const { projects } = useSelector((state) => state.projects);

  const [projectId, setProjectId] = useState("");
  const [pId, setPId] = useState("");
  const [showUploadVideoModal, setUploadVideo] = useState(false);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const [selectedProjects, setSelectedProjects] = useState([]); // Define state to store selected video IDs
  const [selectAllPages, setSelectAllPages] = useState(false); // Inside the VideoList component
  const [selectAll, setSelectAll] = useState(false); // select All in Current page


  /* Edit Project Event and call dispatch function to get project details. */
  const editProject = async (id) => {
    if (id) {
      setLoader(true);
      // open edit modal
      await dispatch(getProject(id));
      setLoader(false);
      setProjectId(id);
    } else {
      // something went wrong
      Swal("Project not found!");
    }
  };
  /* Open Modal to upload media */
  const upload = async (id) => {
    if (id) {
      // open edit modal
      setUploadVideo(true);
      // await dispatch(uploadVideo(id));
      setPId(id);
    } else {
      // something went wrong
      Swal("Error!, Failed to upload video");
    }
  };
  /* Delete Project by id with confirmation and call dispatch function to delete project. */
  const deleteProject = (id) => {
    if (id) {
      Swal({
        title: "Are you sure?",
        text: "You want to delete project!",
        icon: "warning",
        buttons: {
          cancel: "Cancel!",
          ok: {
            text: "Yes, delete it!",
          },
        },
        dangerMode: true,
      }).then(async (confirm) => {
        if (confirm) {
          // call api to delete user
          let res = await dispatch(delProject(id));
          if (res.status === 200) {
            Swal("Poof! Project has been deleted!", {
              icon: "success",
            });
            await dispatch(fetchProjects());
          } else {
            Swal("Something went wrong! please try again", {
              icon: "error",
            });
          }
        } else {
          Swal("You have cancelled operation!", {
            icon: "info",
          });
        }
      });
    }
  };
  // Handler function to manage selecte videos
  const handleSelect = (checked, projectId) => {
    if (selectAllPages) {
      // If all pages are selected
      if (!checked) {
        // Add the video ID to the selectedProjects array
        setSelectedProjects([...selectedProjects, projectId]);
      } else {
        // Remove the video ID from the selectedProjects array
        setSelectedProjects(selectedProjects.filter((id) => id !== projectId));
      }
    } else {
      // If not all pages are selected, handle checkbox as usual
      if (checked) {
        // Add the video ID to the selectedProjects array
        setSelectedProjects([...selectedProjects, projectId]);
      } else {
        // Remove the video ID from the selectedProjects array
        setSelectedProjects(selectedProjects.filter((id) => id !== projectId));
      }
    }
  };

  function areAllValuesInInput1(input1, input2) {
    return input2?.every((value) => input1?.includes(value));
  }

  // Handler function to manage selecte current page videos
  const handleSelectAll = (isSelected) => {
    const allProjctIds = projects?.data?.map((project) => project?.id);
    // Handler function to select or deselect all videos
    if (isSelected) {
      const removeFromCurrent = selectedProjects?.filter(
        (value) => !allProjctIds?.includes(value)
      );
      setSelectedProjects(removeFromCurrent);
    } else {
      let uniqueArr = [
        ...new Set([...allProjctIds, ...(selectedProjects ?? [])]),
      ];
      setSelectedProjects(uniqueArr);
    }
    setSelectAll(!selectAll);
  };


  /* Fetch Project data [if fetch data changed then only dispatch func will be called] */
  useMemo(() => {
    const fetch = async () => {
      await dispatch(fetchProjects()).then((res) => {
        setLoader(false);
      });
    };
    fetch();
  }, []);


  const onSelectProject = (id) => {
    dispatch(selectProject(id));
    navigate(`/media?filter_project_id=${id}`, { state: id });
  };
  /*
   * Set rows of project data array for dataTable list.
   * Returns rows, columns, project id and modal states
   */
  let projectListRow = [];
  let rowObj = {};
  for (let project in projects?.data) {
    rowObj["checkbox"] = auth?.user?.is_lead === "yes" ? (
      <MDTypography display="flex" alignItems="center">
        <Checkbox
          checked={
            selectAllPages
              ? !selectedProjects?.includes(projects?.data[project]?.id)
              : selectedProjects?.includes(projects?.data[project]?.id)
          }
          disabled={selectAllPages}
          onChange={(e) =>
            !selectAllPages &&
            handleSelect(e.target.checked, projects?.data[project]?.id)
          }
        />
      </MDTypography>
    ) : <></>;
    rowObj["title"] = (
      <MDTypography
        variant="caption"
        color="text"
        type="button"
        fontWeight="medium"
        title={`Click here to open project ${projects.data[project].title}'s media`}
        onClick={() => {
          onSelectProject(projects.data[project].id);
        }}
      >
        {projects.data[project].title}
      </MDTypography>
    );
    rowObj["description"] = (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {projects.data[project].description}
      </MDTypography>
    );
    rowObj["assignee"] = (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {projects.data[project].assignees.length}
      </MDTypography>
    );
    rowObj["videos_count"] = (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {
          projects.data[project].videos_count
          // media?.[projects?.data[project]?.id]
        }
      </MDTypography>
    );
    rowObj["updated_at"] = (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {Helpers.formatDate(projects.data[project].updated_at)}
      </MDTypography>
    );
    rowObj["action"] = (
      <>
        <MDTypography
          type="button"
          onClick={() => {
            upload(projects.data[project].id);
          }}
          variant="body"
          color="secondary"
        >
          <Icon>upload</Icon>
        </MDTypography>
        &nbsp;
        {auth?.user.is_lead === "yes" && (
          <>
            <MDTypography
              type="button"
              variant="body"
              color="info"
              onClick={() => {
                editProject(projects.data[project].id);
              }}
            >
              <Icon>edit</Icon>
            </MDTypography>{" "}
            &nbsp;
            <MDTypography
              type="button"
              onClick={() => {
                deleteProject(projects.data[project].id);
              }}
              variant="body"
              color="error"
            >
              <Icon>delete</Icon>
            </MDTypography>
          </>
        )}
      </>
    );
    projectListRow.push(rowObj);
    rowObj = {};
  }

  return {
    columns: [
      {
        Header: (auth?.user?.is_lead === "yes" ? <Checkbox
          disabled={selectAllPages || projects?.data?.length === 0}
          checked={
            projects?.data?.length > 0 &&
            (selectAllPages
              ? !selectedProjects?.some((value) =>
                projects?.data?.map((v) => v?.id)?.includes(value)
              )
              : areAllValuesInInput1(
                selectedProjects,
                projects?.data?.map((v) => v?.id)
              ))
          }
          onClick={() =>
            !selectAllPages &&
            handleSelectAll(
              areAllValuesInInput1(
                selectedProjects,
                projects?.data?.map((v) => v?.id)
              )
            )
          }
          style={{
            transform: "scale(1.1)",
          }}
        /> : <></>),
        accessor: "checkbox",
        align: "center",
        width: "7%",
      },
      {
        Header: "Project Name",
        accessor: "title",
        width: "30%",
        align: "left",
      },
      { Header: "Description", accessor: "description", align: "left" },
      { Header: "Assignee", accessor: "assignee", align: "center" },
      { Header: "Media", accessor: "videos_count", align: "center" },
      { Header: "Last Updated", accessor: "updated_at", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: projectListRow,
    TotalRecords: projects.total,
    projectId: projectId,
    pId: pId,
    setProjectId: setProjectId,
    setPId: setPId,
    showUploadVideoModal: showUploadVideoModal,
    loader: loader,
    selectRows: { selectedProjects, setSelectedProjects },
    selectAllPage: { selectAllPages, setSelectAllPages },
  };
}
