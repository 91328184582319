// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Data
import MDButton from "components/MDButton";

import { Modal } from "react-bootstrap";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addUser } from "store/users";
import { fetchUsers } from "store/users";
import { useState } from "react";
import { Alert, CircularProgress } from "@mui/material";
import { getToast } from "toaster";

function UserAdd({ onShow }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // validation
  const formik = useFormik({
    initialValues: {
      Add: {
        name: "",
        email: "",
        password: ""
      }
    },
    validationSchema: Yup.object({
      Add: Yup.object({
        name: Yup.string().required("Name is required"),
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        password: Yup.string().required("Password is required")
      })
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let res = await dispatch(addUser({ name: values.Add.name, email: values.Add.email, password: values.Add.password }));
      if (res.message) {
        toast.error('Error! Please Try Again', getToast(5000));
      } else {
        toast.success('User Saved Successfully!', getToast(5000));
        onShow();
        await dispatch(fetchUsers());
      }
      setLoading(false);
    }
  });
  return (
    <>
      <Modal show={onShow} onHide={() => onShow()} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput type="text" label="Name" variant="standard"
                  name="Add.name"
                  value={formik.values.Add.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                />
                {formik.errors.Add?.name && formik.touched.Add?.name && (
                  <Alert severity="error" sx={{
                    height: 50
                  }}><small>{formik.errors.Add.name}</small></Alert>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="email" label="Email" variant="standard"
                  name="Add.email"
                  value={formik.values.Add.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                  autoFocus={formik.errors.Add?.email ? true : false}
                />
                {formik.errors.Add?.email && formik.touched.Add?.email && (
                  <Alert severity="error" sx={{
                    height: 50
                  }}><small>{formik.errors.Add.email}</small></Alert>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="password" label="Password" variant="standard"
                  name="Add.password"
                  value={formik.values.Add.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                // autoFocus= {formik.errors.Add ? 'autoFocus' : null} 
                />
                {formik.errors.Add?.password && formik.touched.Add?.password && (
                  <Alert severity="error" sx={{
                    height: 50
                  }}><small>{formik.errors.Add.password}</small></Alert>
                )}
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" disabled={loading} type="submit">
                  Add
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'secondary',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </MDButton>&nbsp;
                <MDButton variant="gradient" color="primary" onClick={() => onShow()}>
                  Cancel
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserAdd;
