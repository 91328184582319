import { createSlice } from '@reduxjs/toolkit';
// import api from '../services/api';
import axios from "axios";
import Service from 'Service';
const initialUser = [];

// Slice
const slice = createSlice({
    name: 'users',
    initialState: {
        users: initialUser,
        userData: {}
    },
    reducers: {
        // Add users data to users state
        fetch: (state, action) => {
            state.users = action.payload.users;
        },
        // add: (state, action) => {
        //     state.users = action.payload.users;
        // },
        // Add users data to userData state for update
        edit: (state, action) => {
            state.userData = action.payload;
        },
    },
});
export default slice.reducer
// Actions
const { fetch, edit } = slice.actions;
export const fetchUsers = (filter, url) => async dispatch => {
    try {
        let config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}` }
        };
        if (filter) {
            config['params'] = filter;
        }
        if (!url) {
            url = `${process.env.REACT_APP_BaseUrl}/users`;
        }
        // API to Fetch Users
        const res = await axios.get(url, config);
        dispatch(fetch(res.data));
        return res.data.users;
    } catch (e) {
        console.error(e.message)
        return e.message;
    }
}
export const addUser = (userData) => async dispatch => {
    try {
        // API to Add New User
        const res = await axios.post(`${process.env.REACT_APP_BaseUrl}/users`, userData, Service.config());
        // dispatch(add(res.data));
        return res;
    } catch (e) {
        console.error(e.message);
        return e;
    }
}
export const getUser = (userId) => async dispatch => {
    try {
        // API to Get User Data
        const res = await axios.get(`${process.env.REACT_APP_BaseUrl}/user/${userId}`, Service.config());
        dispatch(edit(res.data.user));
    } catch (e) {
        console.error(e.message);
        return e;
    }
}
export const changeUserPassword = (userId, passwordData) => async dispatch => {
    try {
        // API to Get User Data
        const res = await axios.patch(`${process.env.REACT_APP_BaseUrl}/users/${userId}/set-password`, passwordData, Service.config());
        return res;
    } catch (e) {
        console.error(e.message);
        return e;
    }
}
export const editUser = (userId, userData) => async dispatch => {
    try {
        // API to Update User Data
        const res = await axios.post(`${process.env.REACT_APP_BaseUrl}/user-update/${userId}`, userData, Service.config());
        return res;
    } catch (e) {
        console.error(e.message);
        return e;
    }
}
export const delUser = (userId) => async dispatch => {
    try {
        // API to Delete User Data
        const res = await axios.post(`${process.env.REACT_APP_BaseUrl}/user-remove/${userId}`, {}, Service.config());
        return res;
    } catch (e) {
        console.error(e.message);
        return e;
    }
}
export const ChangePassword = async (data) => {
    try {
        // API to change password
        const res = await axios.post(`${process.env.REACT_APP_BaseUrl}/change-password`, data, Service.config());
        return res;
    } catch (e) {
        console.error(e.message);
        return e;
    }
}