// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import team2 from "assets/images/team-2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { fetchUsers } from "store/users";
import { Icon } from "@mui/material";
import Swal from "sweetalert";
import { getUser, delUser } from "store/users";
import Helpers from './../../../Helpers';
import ChangeUserPasswordModal from "layouts/User/ChangerUserPassword";

export default function Data() {
  const { users } = useSelector(state => state.users);
  const { auth } = useSelector(state => state);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('');
  const [passwordUserId, setPasswordUserId] = useState('');
  const [loader, setLoader] = useState(false);
  const [showChangeUserPasswordModal, setShowChangeUserPasswordModal] = useState(false);

  /* Fetch Users data [if fetch data changed then only dispatch func will be called] */
  useMemo(() => {
    // Call Fetch users dispatch function
    const fetch = async () => {
      await dispatch(fetchUsers());
    }
    fetch();
  }, []);
  // User Component Combines image, name and email of user 
  const User = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar /**src={image}*/ name={name} size="sm" alt={name} />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const editUser = async (id) => {
    if (id) {
      // setLoader(true);
      // open edit modal
      await dispatch(getUser(id));
      // setLoader(false);
      // send id to user list component
      setUserId(id);
    } else {
      // something went wrong
      Swal("User not found!");
    }
  }

  const changeUserPasswordFn = async (id) => {
    if (id) {
      // setLoader(true);
      // open edit modal
      await dispatch(getUser(id));
      // setLoader(false);
      // send id to user list component
      setUserId(id);
    } else {
      // something went wrong
      Swal("User not found!");
    }
  }

  const deleteUser = (id) => {
    if (id) {
      Swal({
        title: "Are you sure?",
        text: "You want to delete user!",
        icon: "warning",
        buttons: {
          cancel: "Cancel!",
          ok: {
            text: "Yes, delete it!",
          }
        },
        dangerMode: true
      })
        .then(async (confirm) => {
          if (confirm) {
            // call api to delete user
            let res = await dispatch(delUser(id));
            if (res) {
              Swal("Poof! User has been deleted!", {
                icon: "success",
              });
              await dispatch(fetchUsers());
            }
          } else {
            Swal("You have cancelled operation!");
          }
        });
    }
  }

  let userRow = [];
  let rowObj = {};
  for (let user in users.data) {
    rowObj['name'] = <User image={team2} name={users.data[user].name} email={users.data[user].email} />;
    rowObj['updated_at'] = <MDTypography variant="caption" color="text" fontWeight="medium">
      {Helpers.formatDate(users.data[user].updated_at)}
    </MDTypography>
    rowObj['action'] = (<>
      <MDTypography type="button" variant="body" color="info" onClick={() => { editUser(users.data[user].id) }}>
        <Icon>edit</Icon>
      </MDTypography>&nbsp;
      {auth?.user.id === users.data[user].id ? '' : <>
        <MDTypography type="button" onClick={() => { deleteUser(users.data[user].id) }} variant="body" color="error">
          <Icon>delete</Icon>
        </MDTypography>&nbsp;
      <MDTypography type="button" variant="body" color="success" onClick={() => {setPasswordUserId(users.data[user].id); setShowChangeUserPasswordModal(!showChangeUserPasswordModal) }}>
        <Icon>password</Icon>
      </MDTypography>&nbsp;
      </>
      }
      {/* {showChangeUserPasswordModal && <ChangeUserPasswordModal title="Change User Password" onShow={() => { setShowChangeUserPasswordModal(false); }} userId={users.data[user].id} />} */}
    </>)
    userRow.push(rowObj);
    rowObj = {};
  }
  return {
    columns: [
      { Header: "Name", accessor: "name", width: "45%", align: "left" },
      { Header: "Last Updated", accessor: "updated_at", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: userRow,
    userId: userId,
    setUserId: setUserId,
    loader: loader,
    showChangeUserPasswordModal : showChangeUserPasswordModal,
    setShowChangeUserPasswordModal : () => setShowChangeUserPasswordModal(!showChangeUserPasswordModal),
    passwordUserId : passwordUserId
  };
}