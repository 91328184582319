// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Data
import MDButton from "components/MDButton";

import { Modal } from "react-bootstrap";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { editVideo } from "store/video";
import { fetchVideos } from "store/video";
import { useMemo, useState } from "react";
import { Alert, CircularProgress, LinearProgress } from "@mui/material";
import MDBadge from "components/MDBadge";
import { uploadVideo } from "store/video";
import { getToast } from "toaster";

function VideoEdit({ onShow }) {
    const { videosData } = useSelector(state => state.videos);
    const [loading, setLoading] = useState(false);
    const [upload, setUploadVideo] = useState(videosData.filename);
    // Init, Processing, Uploaded, Failed
    const [uploadStatus, setUploadStatus] = useState('Update');
    const dispatch = useDispatch();
    let initialValues = {};
    if (videosData) {
        initialValues = {
            title: videosData.title,
            filename: videosData.filename,
            file: ""
        }
    }
    // validation
    const formik = useFormik({
        initialValues: {
            Edit: initialValues
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            Edit: Yup.object({
                title: Yup.string().required("Title is required"),
                filename: Yup.string()
                    .required("File is required")
            })
        }),
        onSubmit: async (values) => {
            setUploadStatus('Processing...');
            setLoading(true);
            var fileName = '';
            if (values.Edit?.file && values.Edit?.file.name !== videosData?.filename) {
                let formData = new FormData();
                formData.append(
                    "image", values.Edit.file
                )
                let upload = await uploadVideo(formData);
                fileName = upload.data.file
                if (upload.status !== 200) {
                    toast.error('Failed To Upload', getToast(2000));
                    onShow();
                    return true;
                }
            }
            let res = await dispatch(editVideo(videosData.id, { title: values.Edit.title ? values.Edit.title : undefined, filename: fileName ? fileName : undefined }));
            if (res.status === 200) {
                setUploadStatus('Uploaded');
                // setTimeout(async function () {
                    toast.success(res.data.message, getToast(2000));
                    onShow();
                    await dispatch(fetchVideos());
                // }, 2000);
            } else {
                toast.error(res.data.message, getToast(2000));
                setUploadStatus('Failed');
                onShow();
            }
            // onShow();
            setLoading(false);
        }
    });
    useMemo(() => {
        if (formik.values.Edit.filename) {
            setUploadVideo(formik.values.Edit.filename);
        }
    }, [formik.values.Edit.filename]);
    return (
        <>
            <Modal show={onShow} onHide={() => onShow()} centered backdrop="static">
                <Modal.Header>
                    <Modal.Title>Upload Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>Project Name : {videosData.project.title}</p> */}
                    <MDBox pt={4} pb={2} px={2}>
                        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                            <MDBox mb={2}>
                                <MDInput type="text" label="Title" variant="standard"
                                    name="Edit.title"
                                    value={formik.values.Edit.title}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    fullWidth
                                />
                                {formik.errors.Edit?.title && formik.touched.Edit?.title && (
                                    <Alert severity="error" sx={{
                                        height: 50
                                    }}><small>{formik.errors.Edit.title}</small></Alert>
                                )}
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput type="file" label="Upload Video" variant="standard"
                                    name="Edit.filename"
                                    onChange={(event) => {
                                        formik.setFieldValue("Edit.filename", event.target.files[0].name);
                                        formik.setFieldValue("Edit.file", event.target.files[0]);
                                    }}
                                    fullWidth
                                    autoFocus={formik.errors.Edit?.filename ? true : false}
                                />
                                <h6>{upload}</h6>
                                {/* {uploadStatus === 'Processing' && <LinearProgress style={{ overflow: 'hidden' }} />}
                                {uploadStatus === 'Uploaded' && <MDBox align='center'>  <MDBadge badgeContent={uploadStatus} color={uploadStatus === 'Uploaded' ? 'success' : 'danger'} size="md" variant="contained" container /> </MDBox>} */}
                            </MDBox>
                            {formik.errors.Edit?.filename && formik.touched.Edit?.filename && (
                                <Alert severity="error" sx={{
                                    height: 50
                                }}><small>{formik.errors.Edit.filename}</small></Alert>
                            )}
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" color={uploadStatus === 'Uploaded' ? 'success' : 'info'} disabled={loading} type="submit">
                                    {uploadStatus ? uploadStatus : "Update"}
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: 'secondary',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </MDButton>&nbsp;
                                <MDButton variant="gradient" color="primary" disabled={loading} onClick={() => onShow()}>
                                    Cancel
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default VideoEdit;
